import React from 'react';

export default function Button(props) {
    return (
        <div className="button">
            <a id={props.buttonID} href={props.buttonsource} onclick={props.buttononclick}>
                <h3>{props.buttonname}</h3>
            </a>
        </div>
    )
}