import React from 'react';

export default function Productbox(props) {
    const on_sale = props.productsale;
    if (on_sale) {
        return (
            <div className="product">
                <a id={props.productID} href={props.productpage}>
                    <img src={props.productimage} alt={props.productname} />
                    <h4>{props.productname}</h4>
                    <div className="productprice">
                        <p className="prevprice">${props.regprice}</p>
                        <p className="currentprice">${props.saleprice}</p>
                    </div>
                </a>
            </div>
        )
    } else {
        return (
            <div className="product">
                <a id={props.productID} href={props.productpage}>
                    <img src={props.productimage} alt={props.productname} />
                    <h4>{props.productname}</h4>
                    <div className="productprice">
                        <p className="currentprice">${props.regprice}</p>
                    </div>
                </a>
            </div>
        )
    }
}