import {React, useState} from 'react';
import Logo from '../images/forg3d-logo.png';

// Checks current state of hamburger menu, either clicked on or not
export default function Navbar() {
    const [burger, setBurger] = useState(false);
    let ToggleClass = () => {
        setBurger(!burger);
    };

    return (
        <nav>
            <div id="logo">
                <a href="/">
                    <img src={Logo} alt="Forg3d" />
                </a>
            </div>
            <div id="navmenu">
                <div id="burger" className={burger ? "crossed" : ""} onClick={ToggleClass}>
                    <span id="firstline"></span>
                    <span id="secondline"></span>
                    <span id="thirdline"></span>
                    <ul className={burger ? "dropdown" : "hidden"}>
                        <li><a href="https://enyoc.square.site/shop/rv_products/2">RV <br /> Products</a></li>
                        <li><a href="https://enyoc.square.site/shop/jeep_products/3">Jeep <br /> Products</a></li>
                        <li><a href="https://enyoc.square.site/shop/golf_products/4">Golf <br /> Products</a></li>
                        <li><a href="https://enyoc.square.site/shop/ebike_products/5">E-Bike <br /> Products</a></li>
                        <li><a href="https://enyoc.square.site/shop/home_products/6">Home <br /> Products</a></li>
                        <li><a href="https://enyoc.etsy.com">Etsy <br /> Products</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}