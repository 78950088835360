import React from 'react';
import Button from '../components/button.js';
import productlist from '../products.json';
import Productbox from '../components/productbox.js';
import Servicebox from '../components/servicebox.js';

function Pennydrop() {

    let test = [];
    for (let product of productlist.products) {
      if (product.featured === true) {
        test.push(<Productbox productsale={product.sale} productpage={product.url} productimage={require(`../images/${product.code_name}.jpeg`)} productname={product.product_name} regprice={product.reg_price.toFixed(2)} saleprice={product.sale_price.toFixed(2)} />)
      }
    }

  return (
    <div id="pennydrop">
      <header id="gamebanner">
        <div id="titleaction">
          <h1>How to Play Penny Drop</h1>
        </div>
      </header>
      <section id="gameinstructions">
        <p><span className="italic">Penny Drop</span> is meant to be played by two or more people.</p>
        <p><span className="bold">Each Player</span> needs 12 Pennies &#40;or Washers&#41;</p>
        <p className="gamenote">Note - 10 Pennies can be used if you want to make a faster game!</p>
        <p className="bold">The object of the game is to be the first to get rid of all of your pennies.</p>
        <ol className="instructionlist">
            <li>Determine who goes first by each player rolling the single dice cube. Highest number goes first; then play proceeds in a clockwise direction.</li>
            <li>The player rolls the die and must put a penny in the game board slot corresponding to the number shown on the die.</li>
            <li>If the player rolls a number that already has a penny in it, they must take all of the pennies showing on the board and then the turn goes to the next player.</li>
            <li>A player must take at least one roll, but can take as many rolls as they want until they get stuck as in Rule 3.</li>
            <li>Slot 6 lets the penny go inside the box so you can roll as many as 6's as possible without penalty.</li>
            <li>The current game lasts until a player runs out of pennies. The other players count their remaining pennies and add them to the score. The pennies are redistributed to start the next game.</li>
            <li>Play continues until one player reaches 100 points, at which point the player with the lowest score is declared the winner.</li>
        </ol>
        <p className="gamenote"><span className="bold">Tip:</span> Wager on the game by having each player put a quarter inside the box prior to the game starting. The winner gets to keep the quarters!</p>
      </section>
      <section id="buyinstructions">
        <div id="leftbutton">
          <Button buttonID="buygamebutton" buttonsource="https://www.etsy.com/listing/1732592235/" buttonname="Buy Penny Washers" />
        </div>
      </section>
    </div>
  );
}

export default Pennydrop;