import React from 'react';

export default function Servicebox(props) {
    return (
        <div className="service">
            <img src={props.serviceimage} alt={props.servicename} />
            <h4>{props.servicename}</h4>
            <p className="servicedesc">{props.description}</p>
        </div>
    )
}