import React from 'react';
import Navbar from './components/navbar.js';
import Home from './pages/home.js';
import Pennydrop from './pages/pennydrop.js';
import Footer from './components/footer.js';

function App() {

    let component;
    switch(window.location.pathname) {
      case '/':
        component = <Home />;
        break;
      case '/pennydrop':
        component = <Pennydrop />
        break;
      default:
        component = <Home />;
        break;
    }

  return (
    <body>
      <Navbar />
      {component}
      <Footer />
    </body>
  );
}

export default App;
